import Head from 'next/head'

const SEO = ({
    // Basic meta tags
    title,
    description,
    keywords,
    robots,
    viewport = 'width=device-width, initial-scale=1', // default viewport
    canonical,

    // Open Graph tags
    openGraph = {}, // { title, description, url, type, image }

    // Twitter card tags
    twitter = {}, // { card, title, description, image }

    // Schema.org structured data (as a JSON object)
    schema,

    // hreflang links (array of objects: { hrefLang, href })
    hreflangs = [],
}) => {
    return (
        <Head>
            {/* Basic Meta Tags */}
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords} />}
            {robots && <meta name="robots" content={robots} />}
            {viewport && <meta name="viewport" content={viewport} />}
            {canonical && <link rel="canonical" href={canonical} />}

            {/* Open Graph Meta Tags */}
            {openGraph.title && <meta property="og:title" content={openGraph.title} />}
            {openGraph.description && <meta property="og:description" content={openGraph.description} />}
            {openGraph.url && <meta property="og:url" content={openGraph.url} />}
            {openGraph.type && <meta property="og:type" content={openGraph.type} />}
            {openGraph.image && <meta property="og:image" content={openGraph.image} />}

            {/* Twitter Card Meta Tags */}
            {twitter.card && <meta name="twitter:card" content={twitter.card} />}
            {twitter.title && <meta name="twitter:title" content={twitter.title} />}
            {twitter.description && <meta name="twitter:description" content={twitter.description} />}
            {twitter.image && <meta name="twitter:image" content={twitter.image} />}

            {/* JSON-LD Schema Markup */}
            {schema && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
                />
            )}

            {/* hreflang Links for Multilingual Support */}
            {hreflangs.map((link, index) =>
                link.hrefLang && link.href ? (
                    <link key={index} rel="alternate" hrefLang={link.hrefLang} href={link.href} />
                ) : null
            )}
        </Head>
    )
}

export default SEO
