import { useEffect, useState } from "react";
import Link from 'next/link';
import Image from 'next/image';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useTranslation from "next-translate/useTranslation";
import { useRouter } from 'next/router';
// Import coinpicker styles

export function getNetworkLogo(chain, networks) {
    if (chain) {
        if (networks && Array.isArray(networks)) {
            const foundNetworkChild = networks.find(net => net.chain.toString().toLowerCase() === chain.toString().toLowerCase());
            if (foundNetworkChild) {
                return foundNetworkChild.logo;
            }
        }
    }
    return false;
}

export default function HeroWidget({ assets, networks, parentIdentifier, parentNet, parentName, childIdentifier, childNet, childName, showTitle = false }) {

    const { t } = useTranslation("coinpicker");
    const router = useRouter();
    const finalShowTitle = showTitle || (router.pathname === '/pairs');

    const containerClasses = `p-3 coinpicker-container header-bottom ${router.pathname === '/' ? '' : 'coinpicker-container-extra'}`;

    const [sendValue, setSendValue] = useState("1");
    const [debouncedSendValue, setDebouncedSendValue] = useState("1");

    const [parentConfig, setParentConfig] = useState(() => {
        if (parentIdentifier && parentNet && childIdentifier && childNet) {
            return {
                displayCoin: parentName,
                coin: parentName,
                id: parentNet + '.' + parentIdentifier,
                net: parentNet
            };
        } else {
            return {
                displayCoin: 'SOL',
                coin: 'SOL',
                id: 'SOL.SOL',
                net: 'SOL'
            };
        }
    });

    const [childConfig, setChildConfig] = useState(() => {
        if (parentIdentifier && parentNet && childIdentifier && childNet) {
            return {
                displayCoin: childName,
                coin: childName,
                id: childNet + '.' + childIdentifier,
                net: childNet
            };
        } else {
            return {
                displayCoin: 'USDC',
                coin: 'USDC',
                id: 'SOL.USDC-EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
                net: 'SOL'
            };
        }
    });

    const [receiveValue, setReceiveValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [sellAmountUSD, setSellAmountUSD] = useState("0");
    const [buyAmountUSD, setBuyAmountUSD] = useState("0");
    const [showParentModal, setShowParentModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [closeVisible, setCloseVisible] = useState(false);

    const [visibleAssetsCount, setVisibleAssetsCount] = useState(20);
    const [visibleAssets, setVisibleAssets] = useState([]);
    const [assetsContainerHeight, setAssetsContainerHeight] = useState(visibleAssetsCount * 40);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [searchPlaceholder, setSearchPlaceholder] = useState("");
    const [fadeInAssets, setFadeInAssets] = useState(false);

    const [mappedAssets, setMappedAssets] = useState([]);
    const [filteredAssets, setFilteredAssets] = useState([]);

    const handleOpenParentModal = () => {
        setModalType("parent");
        setShowParentModal(true);
        setTimeout(() => {
            setCloseVisible(true);
        }, 50);
        handleNetworkSelect(null); // Trigger asset loading for "All Networks"
    };

    const handleOpenChildModal = () => {
        setModalType("child");
        setShowParentModal(true);
        setTimeout(() => {
            setCloseVisible(true);
        }, 50);
        handleNetworkSelect(null); // Trigger asset loading for "All Networks"
    };

    const handleCloseParentModal = () => {
        setModalType(null);
        setShowParentModal(false);
        setCloseVisible(false);
        setSelectedNetwork(null); // Reset selected network when closing modal
        setSearchTerm(""); // Reset search term too
    };

    const handleNetworkSelect = (network) => {
        setFadeInAssets(false);
        setTimeout(() => {
            setSelectedNetwork(network);
            setFadeInAssets(true);
        }, 300);
    };

    var sendCoinIcon = '';
    var parentNetImage = '';
    var childNetImage = '';
    var receiveCoinIcon = '';

    // Update the isNativeToken function to handle exceptions like ARB on ARB chain
    const isNativeToken = (symbol, chain) => {
        const nativeTokenMap = {
            'BASE': 'ETH',
            'MAYA': 'CACAO',
            'THOR': 'RUNE',
            'ARB': 'ETH', // Exception: ARB's true native token is ETH
            'GAIA': 'ATOM',
            'BSC': 'BNB',
            "BTC": "BTC",
            "ETH": "ETH",
            "SOL": "SOL",
            "AVAX": "AVAX",
            "DOGE": "DOGE",
            "LTC": "LTC",
            "XRD": "XRD",
            "KUJI": "KUJI",
            "DASH": "DASH",
        };

        const nonNativeExceptions = {
            'ARB': ['ARB'], // ARB on ARB chain is not native
            'BASE': ['BASE'],
        };

        symbol = (symbol || "").toUpperCase();
        chain = (chain || "").toUpperCase();

        // Check if the token is explicitly marked as non-native
        if (nonNativeExceptions[chain]?.includes(symbol)) {
            return false;
        }

        // Check standard case (symbol matches chain)
        if (symbol === chain) return true;

        // Check special cases using the mapping
        return nativeTokenMap[chain] === symbol;
    };

    const unformatNumber = (value) => {
        // Ensure value is treated as a string before replace
        return value ? value.toString().replace(/,/g, '') : value;
    };
    function formatUsd(value) {
        const parsed = parseFloat(value) || 0;
        const formatted = parsed.toFixed(1);
        const parts = formatted.split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    }

    useEffect(() => {
        if (assets && Array.isArray(assets) && networks && Array.isArray(networks)) {
            const nativeTokenMap = {
                'BASE': 'ETH',
                'MAYA': 'CACAO',
                'THOR': 'RUNE',
                'ARB': 'ETH',
                'GAIA': 'ATOM',
                'BSC': 'BNB',
                "BTC": "BTC",
                "ETH": "ETH",
                "SOL": "SOL",
                "AVAX": "AVAX",
                "DOGE": "DOGE",
                "LTC": "LTC",
                "XRD": "XRD",
                "KUJI": "KUJI",
                "DASH": "DASH",
            };
            const nonNativeExceptions = {
                'ARB': ['ARB'], // ARB on ARB chain is not native
                'BASE': ['BASE'],
            };

            // Check if the token is explicitly marked as non-native

            const isNativeTokenForAsset = (asset) => {
                const symbol = (asset.ticker || "").toUpperCase();
                const chain = (asset.chain || "").toUpperCase();
                if (nonNativeExceptions[chain]?.includes(symbol)) {
                    return false;
                }
                if (symbol === chain) return true;
                return nativeTokenMap[chain] === symbol;
            };

            const mapped = assets.map(asset => {
                const netImage = getNetworkLogo(asset.chain.toString(), networks);
                return { ...asset, netImage };
            });
            const sortedMapped = mapped.sort((a, b) => {
                const aIsNative = isNativeTokenForAsset(a);
                const bIsNative = isNativeTokenForAsset(b);
                if (aIsNative && !bIsNative) return -1;
                if (!aIsNative && bIsNative) return 1;
                return 0;
            });
            setMappedAssets(sortedMapped);
        }
    }, [assets, networks]);

    if (mappedAssets && mappedAssets.length > 0) {
        const foundAssetParent = mappedAssets.find(asset => asset.identifier.toLowerCase() === parentConfig.id.toLowerCase());
        if (foundAssetParent) {
            sendCoinIcon = foundAssetParent.icon;
            parentNetImage = foundAssetParent.netImage;
        }
        const foundAssetChild = mappedAssets.find(asset => asset.identifier.toLowerCase() === childConfig.id.toLowerCase());
        if (foundAssetChild) {
            receiveCoinIcon = foundAssetChild.icon;
            childNetImage = foundAssetChild.netImage;
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSendValue(sendValue);
        }, 800);
        return () => clearTimeout(timer);
    }, [sendValue]);

    useEffect(() => {
        if (!parentConfig.id || !childConfig.id) return;
        const fetchReceiveValue = async () => {
            try {
                setIsLoading(true);
                const url = `/api/aggregator/quote?sellAsset=${parentConfig.id}&buyAsset=${childConfig.id}&sellAmount=${debouncedSendValue}&slippage=10`;
                const res = await fetch(url);
                const data = await res.json();

                const message = data?.response?.message;
                if (message && message.includes("Min: ")) {
                    const afterMin = message.split("Min: ")[1];
                    if (afterMin) {
                        const numericStr = afterMin.split(" ")[0];
                        const minNumber = parseFloat(numericStr);
                        if (!isNaN(minNumber)) {
                            if (minNumber > parseFloat(sendValue)) {
                                setSendValue(minNumber.toString());
                            }
                        }
                    }
                }

                const expectedBuy = (data?.response?.buyAmount ?? data?.response?.expectedBuyAmount ?? "0");
                setReceiveValue(expectedBuy);
                setSellAmountUSD(data?.response?.sellAmountUSD || "0");
                setBuyAmountUSD(data?.response?.buyAmountUSD || "0");
            } catch (err) {
                console.error(err);
                setReceiveValue("0");
            } finally {
                setIsLoading(false);
            }
        };

        fetchReceiveValue();
    }, [debouncedSendValue, parentConfig.id, childConfig.id]);

    useEffect(() => {
        if (showParentModal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        // Filter assets based on search term and selected network
        const filtered = mappedAssets.filter(asset => {
            // Filter by search term
            const matchesSearch = !searchTerm ||
                asset.ticker.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (asset.name && asset.name.toLowerCase().includes(searchTerm.toLowerCase()));

            // Filter by selected network
            const matchesNetwork = !selectedNetwork || asset.chain === selectedNetwork;

            return matchesSearch && matchesNetwork;
        });

        setFilteredAssets(filtered);
        setVisibleAssets(filtered.slice(0, visibleAssetsCount));
    }, [showParentModal, searchTerm, selectedNetwork, visibleAssetsCount, mappedAssets]);

    useEffect(() => {
        setVisibleAssetsCount(20);
    }, [searchTerm, selectedNetwork]);

    useEffect(() => {
        if (selectedNetwork === null) {
            setSearchPlaceholder(t('widget.searchToken'));
        } else {
            // Find the selected network to get its fullName
            const selectedNetworkObj = networks.find(net => net.chain === selectedNetwork);
            if (selectedNetworkObj) {
                setSearchPlaceholder(`${t('widget.searchToken')} ${t('widget.on')} ${selectedNetworkObj.fullName}`);
            } else {
                setSearchPlaceholder(t('widget.searchToken'));
            }
        }
    }, [selectedNetwork, networks, t]);

    useEffect(() => {
        setParentConfig(prev => ({
            ...prev,
            id: `${parentNet}.${parentIdentifier}`,
            net: parentNet,
            name: parentName
        }));
    }, [parentIdentifier, parentNet, parentName]);

    useEffect(() => {
        setChildConfig(prev => ({
            ...prev,
            id: `${childNet}.${childIdentifier}`,
            net: childNet,
            name: childName
        }));
    }, [childIdentifier, childNet, childName]);

    useEffect(() => {
        const { slug } = router.query;
        if (typeof slug === 'string' && slug.trim() !== "" && (slug.split('-').length === 4 || slug.split('-').length === 5 || slug.split('-').length === 6)) {
            if (slug.split('-').length === 6) {
                const parts = slug.split('-');
                // If parts[2] is a known network chain, then assume Pattern B: [pNet, pCoin, cNet, cCoin, contract]
                if (networks && networks.some(net => net.chain.toLowerCase() === parts[4].toLowerCase())) {
                    const [pNet, pCoin, cNet, cCoin, contract] = parts;
                    setParentConfig({
                        displayCoin: pCoin,
                        coin: pCoin,
                        id: `${pNet}.${pCoin}-${contract}`,
                        net: pNet
                    });
                    setChildConfig({
                        displayCoin: cCoin,
                        coin: cCoin,
                        id: `${cNet}.${cCoin}-${contract}`,
                        net: cNet
                    });
                } else {
                    // Otherwise assume Pattern A: [pNet, pCoin, contract, cNet, cCoin]
                    const [pNet, pCoin, contract, cNet, cCoin] = parts;
                    setParentConfig({
                        displayCoin: pCoin,
                        coin: pCoin,
                        id: `${pNet}.${pCoin}-${contract}`,
                        net: pNet
                    });
                    setChildConfig({
                        displayCoin: cCoin,
                        coin: cCoin,
                        id: `${cNet}.${cCoin}`,
                        net: cNet
                    });
                }
            }

            if (slug.split('-').length === 5) {
                const parts = slug.split('-');
                // If parts[2] is a known network chain, then assume Pattern B: [pNet, pCoin, cNet, cCoin, contract]
                if (networks && networks.some(net => net.chain.toLowerCase() === parts[2].toLowerCase())) {
                    const [pNet, pCoin, cNet, cCoin, contract] = parts;
                    setParentConfig({
                        displayCoin: pCoin,
                        coin: pCoin,
                        id: `${pNet}.${pCoin}`,
                        net: pNet
                    });
                    setChildConfig({
                        displayCoin: cCoin,
                        coin: cCoin,
                        id: `${cNet}.${cCoin}-${contract}`,
                        net: cNet
                    });
                    if (networks && networks.some(net => net.chain.toLowerCase() === parts[2].toLowerCase())) {
                        const [pNet, pCoin, cNet, cCoin, contract] = parts;
                        setParentConfig({
                            displayCoin: pCoin,
                            coin: pCoin,
                            id: `${pNet}.${pCoin}`,
                            net: pNet
                        });
                        setChildConfig({
                            displayCoin: cCoin,
                            coin: cCoin,
                            id: `${cNet}.${cCoin}-${contract}`,
                            net: cNet
                        });
                    }
                } else {
                    // Otherwise assume Pattern A: [pNet, pCoin, contract, cNet, cCoin]
                    const [pNet, pCoin, contract, cNet, cCoin] = parts;
                    setParentConfig({
                        displayCoin: pCoin,
                        coin: pCoin,
                        id: `${pNet}.${pCoin}-${contract}`,
                        net: pNet
                    });
                    setChildConfig({
                        displayCoin: cCoin,
                        coin: cCoin,
                        id: `${cNet}.${cCoin}`,
                        net: cNet
                    });
                }
            }
            if (slug.split('-').length === 4) {
                var [pNet, pCoin, cNet, cCoin] = slug.split('-');
                setParentConfig({
                    displayCoin: pCoin,
                    coin: pCoin,
                    id: `${pNet}.${pCoin}`,
                    net: pNet
                });
                setChildConfig({
                    displayCoin: cCoin,
                    coin: cCoin,
                    id: `${cNet}.${cCoin}`,
                    net: cNet
                });
            }
        } else {
            // No URL parameter provided: fallback to hardcoded values
            setParentConfig({
                displayCoin: 'SOL',
                coin: 'SOL',
                id: 'SOL.SOL',
                net: 'SOL'
            });
            setChildConfig({
                displayCoin: 'USDC',
                coin: 'USDC',
                id: 'SOL.USDC-EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
                net: 'SOL'
            });
        }
    }, [router.query.slug]);

    const handleModalScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            setVisibleAssetsCount(prev => {
                const newCount = prev + 20;
                setVisibleAssets(filteredAssets.slice(0, newCount));
                return newCount;
            });
        }
    };

    const handleSwap = () => {
        setSendValue(unformatNumber(receiveValue));
        setParentConfig(childConfig);
        setChildConfig(parentConfig);
    };

    const handleImageError = (e) => {
        const fallbackLetter = e.target.alt ? e.target.alt.charAt(0).toUpperCase() : "?";
        const fallbackDiv = document.createElement("div");

        // Add class instead of inline styles
        fallbackDiv.className = "coinpicker-fallback-icon-js";
        fallbackDiv.textContent = fallbackLetter;

        const parent = e.target.parentElement;
        if (parent && parent.contains(e.target)) {
            parent.replaceChild(fallbackDiv, e.target);
        } else {
            e.target.style.display = "none";
        }
    };

    const shouldDisplayNetImage = (asset) => {
        const exceptions = ["GAIA.ATOM", "BSC.BNB"];
        const assetIdentifier = `${asset.chain ?? asset.net}.${asset.ticker ?? asset.coin}`.toUpperCase();

        // Return false if the asset is in the exceptions list
        if (exceptions.includes(assetIdentifier)) {
            return false;
        }

        // Return true if the chain is not the same as the token
        return ((asset.ticker ?? asset.coin) || "").toUpperCase() !== ((asset.chain ?? asset.net) || "").toUpperCase();
    };

    const isPending = isLoading || (sendValue !== debouncedSendValue);

    return (
        <>
            <div className={containerClasses}>
                {finalShowTitle && (
                    <span className="mb-2 fs-3 text-muted m-2">{t("widget.title")}</span>
                )}
                {/* Send Input */}
                <div className="mb-3 coinpicker-input-wrapper">
                    <label htmlFor="send-amount" className="form-label aggregator-label coinpicker-form-label">
                        {t("widget.send")}
                    </label>
                    <div className="input-group w-100 coinpicker-input-group">
                        <div className="coinpicker-input-container">
                            <input
                                id="send-amount"
                                type="text"
                                className="form-control coinpicker-amount-input"
                                value={sendValue}
                                onChange={(e) => setSendValue(e.target.value.replace(/[^0-9.]/g, ''))}
                                onFocus={(e) => setSendValue(e.target.value.replace(/[^0-9.]/g, ''))}
                                onBlur={(e) => setSendValue(e.target.value.replace(/[^0-9.]/g, ''))}
                            />
                            {isPending ? (
                                <div className="coinpicker-skeleton-wrapper">
                                    <SkeletonTheme baseColor="#182432" highlightColor="#344053">
                                        <Skeleton
                                            style={{
                                                width: "30%",
                                                height: "20px",
                                                left: "15px",
                                                borderRadius: "4px",
                                                "--base-color": "#182432",
                                                "--highlight-color": "#344053"
                                            }}
                                        />
                                    </SkeletonTheme>
                                </div>
                            ) : (
                                <div
                                    id="send-usd"
                                    className="form-control coinpicker-usd-input"
                                    style={{ padding: '5px 10px 5px 15px' }}
                                >
                                    {'$' + formatUsd(buyAmountUSD)}
                                </div>
                            )}
                        </div>
                        <span className="input-group-text" onClick={handleOpenParentModal}>
                            <div className="coinpicker-coin-icon-container">
                                {sendCoinIcon ? (
                                    <div className="position-relative">
                                        <Image
                                            src={sendCoinIcon}
                                            alt="send-coin-icon"
                                            width={40}
                                            height={40}
                                            className="coinpicker-coin-image"
                                            onError={handleImageError}
                                        />
                                        {parentNetImage && shouldDisplayNetImage(parentConfig) && (
                                            <div className="position-absolute coinpicker-network-badge-container">
                                                <Image
                                                    src={parentNetImage}
                                                    alt={parentConfig.net}
                                                    width={16}
                                                    height={16}
                                                    className="coinpicker-network-badge"
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <SkeletonTheme baseColor="#121a27" highlightColor="#344053">
                                        <Skeleton circle height={40} width={40} />
                                    </SkeletonTheme>
                                )}
                            </div>
                            <div className="coinpicker-coin-info">
                                <strong className="coinpicker-coin-symbol">{parentConfig.displayCoin}</strong>
                                <br />
                                <small className="text-muted">
                                    {isNativeToken(parentConfig.coin, parentConfig.net) ? "Native" : parentConfig.net}
                                </small>
                            </div>
                            <svg
                                className="coinpicker-dropdown-icon"
                                focusable="false"
                                fill="#fff"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                            >
                                <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="mb-3 trk-btn trk-btn--primary trk-btn--arrow coinpicker-swap-button" onClick={handleSwap}>
                    <svg
                        focusable="false"
                        width={25}
                        height={25}
                        fill="#fff"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                    >
                        <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99zM9 3 5 6.99h3V14h2V6.99h3z"></path>
                    </svg>
                </div>
                <div>
                    {/* Receive Input */}
                    <div className="mb-3 coinpicker-input-wrapper">
                        <label htmlFor="receive-value" className="form-label aggregator-label coinpicker-form-label">
                            {t("widget.receive")}
                        </label>
                        <div className="input-group w-100 coinpicker-input-group">
                            {isPending ? (
                                <div className="skeleton-wrapper-cs coinpicker-skeleton-wrapper">
                                    <SkeletonTheme baseColor="#182432" highlightColor="#344053">
                                        <Skeleton className="coinpicker-receive-skeleton" />
                                    </SkeletonTheme>
                                </div>
                            ) : (
                                <div className="coinpicker-input-container">
                                    <input
                                        id="receive-value"
                                        type="text"
                                        readOnly
                                        className="form-control coinpicker-amount-input"
                                        value={receiveValue}
                                    />
                                    <div
                                        id="receive-usd"
                                        className="form-control coinpicker-usd-input"
                                        style={{ padding: '5px 10px 5px 15px' }}
                                    >
                                        {'$' + formatUsd(buyAmountUSD)}
                                    </div>
                                </div>
                            )}
                            <span className="input-group-text" onClick={handleOpenChildModal}>
                                <div className="coinpicker-coin-icon-container">
                                    {receiveCoinIcon ? (
                                        <div className="position-relative">
                                            <Image
                                                src={receiveCoinIcon}
                                                alt="receive-coin-icon"
                                                width={40}
                                                height={40}
                                                className="coinpicker-coin-image"
                                                onError={handleImageError}
                                            />
                                            {childNetImage && shouldDisplayNetImage(childConfig) && (
                                                <div className="position-absolute coinpicker-network-badge-container">
                                                    <Image
                                                        src={childNetImage}
                                                        alt={childConfig.net}
                                                        width={16}
                                                        height={16}
                                                        className="coinpicker-network-badge"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <SkeletonTheme baseColor="#121a27" highlightColor="#344053">
                                            <Skeleton circle height={40} width={40} />
                                        </SkeletonTheme>
                                    )}
                                </div>
                                <div className="coinpicker-coin-info">
                                    <strong className="coinpicker-coin-symbol">{childConfig.displayCoin}</strong>
                                    <br />
                                    <small className="text-muted">
                                        {isNativeToken(childConfig.coin, childConfig.net) ? "Native" : childConfig.net}
                                    </small>
                                </div>
                                <svg
                                    className="coinpicker-dropdown-icon"
                                    focusable="false"
                                    fill="#fff"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <Link
                        href={`https://app.instaswap.com/swap/?base_asset=${parentConfig.id}&quote_asset=${childConfig.id}&amount=${sendValue}`}
                        target="_blank"
                        className="trk-btn trk-btn--primary trk-btn--arrow w-100"
                    >
                        {t("widget.swapNow")}
                    </Link>
                </div>
            </div >
            {showParentModal && (
                <div className="coinpicker-modal-overlay" onClick={handleCloseParentModal}>
                    <div
                        className="coinpicker-modal-content"
                        onClick={(e) => e.stopPropagation()}
                        data-aos="fade-in"
                        data-aos-duration="200"
                    >
                        <div className={`coinpicker-modal-close-container ${closeVisible ? 'closeVisible' : ''}`} onClick={handleCloseParentModal}>
                            <div className="trk-btn trk-btn--primary trk-btn--arrow coinpicker-modal-close-button">
                                <a className="coinpicker-modal-close-text" >
                                    X
                                </a>
                            </div>
                        </div>
                        <div className="coinpicker-network-container">
                            <div
                                key="all-networks"
                                className={selectedNetwork === null ? "coinpicker-all-networks-selected" : "coinpicker-all-networks"}
                                onClick={() => handleNetworkSelect(null)}
                            >
                                <span>
                                    <span className="desktop-only-text">{t("widget.allNetworks")}</span>
                                    <span className="mobile-only-text">{t('widget.all')}</span>
                                </span>
                            </div>
                            {networks && networks.map((network, index) => (
                                <div
                                    key={index}
                                    className={selectedNetwork === network.chain ? "coinpicker-network-item-selected" : "coinpicker-network-item"}
                                    onClick={() => handleNetworkSelect(network.chain)}
                                >
                                    {network.logo && (
                                        <Image
                                            src={network.logo}
                                            alt={network.chain}
                                            width={22}
                                            height={22}
                                            className="coinpicker-network-logo"
                                        />
                                    )}
                                    <span className="coinpicker-network-text">
                                        {network.chain}
                                        <small className="coinpicker-network-subtext desktop-only-text">
                                            {network.fullName}
                                        </small>
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="coinpicker-assets-container">
                            <div className="coinpicker-search-container">
                                <div className="coinpicker-search-input-wrapper">
                                    <input
                                        type="text"
                                        placeholder={searchPlaceholder}
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="coinpicker-search-input"
                                    />
                                    {/* Only render the token count div if there's more than 1 token */}
                                    {((selectedNetwork === null && mappedAssets.length > 1) ||
                                        (selectedNetwork !== null && filteredAssets.length > 1)) && (
                                            <div className="coinpicker-token-count">
                                                {selectedNetwork === null
                                                    ? `${mappedAssets.length} ${t("widget.tokens")}`
                                                    : `${filteredAssets.length} ${t("widget.tokens")}`}
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div
                                className={`coinpicker-scroll-container ${fadeInAssets ? 'coinpicker-fade-in' : 'coinpicker-fade-out'}`}
                                onScroll={handleModalScroll}
                            >
                                {visibleAssets && Array.isArray(visibleAssets) && visibleAssets.map((asset, index) => (
                                    <div
                                        key={index}
                                        className="coinpicker-asset-item"
                                        onClick={() => {
                                            if (modalType === "parent") {
                                                setParentConfig({
                                                    displayCoin: asset.ticker,
                                                    coin: asset.ticker,
                                                    id: asset.identifier,
                                                    net: asset.chain
                                                });
                                            } else if (modalType === "child") {
                                                setChildConfig({
                                                    displayCoin: asset.ticker,
                                                    coin: asset.ticker,
                                                    id: asset.identifier,
                                                    net: asset.chain
                                                });
                                            }
                                            handleCloseParentModal();
                                        }}
                                    >
                                        <div className="coinpicker-asset-icon-container">
                                            {asset.icon ? (
                                                <Image
                                                    src={asset.icon}
                                                    alt={`${asset.ticker}-icon`}
                                                    width={50}
                                                    height={50}
                                                    className="coinpicker-asset-image"
                                                    onError={handleImageError}
                                                    loading="lazy"
                                                />
                                            ) : (
                                                <div className="coinpicker-asset-fallback-icon">
                                                    {asset.ticker ? asset.ticker[0] : "?"}
                                                </div>
                                            )}
                                            {/* Show netImage only if it passes the shouldDisplayNetImage check */}
                                            {asset.netImage && shouldDisplayNetImage(asset) && (
                                                <div className="coinpicker-asset-network-badge">
                                                    <Image
                                                        src={asset.netImage}
                                                        alt={`${asset.chain}-badge`}
                                                        width={16}
                                                        height={16}
                                                        className="coinpicker-asset-network-badge-img"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="coinpicker-asset-text">
                                            <strong>{asset.ticker}</strong>
                                            <br />
                                            <small className="text-muted">
                                                {isNativeToken(asset.ticker, asset.chain) ? "Native" : asset.chain}
                                            </small>
                                        </div>
                                        <div className="coinpicker-asset-name">
                                            <small className="text-muted">
                                                {asset.name ?? asset.ticker}
                                            </small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    );
}
